import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import { PageParams } from "../../../types";
import Img from "gatsby-image";

import "./creation.scss";

import { InView } from "react-intersection-observer";

import Layout from "../../components/layout/layout";
import Button from "../../components/button/button";
import Hero from "../../components/hero/hero";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import { Portfolio, PortfolioNode } from "../../utils/portfolio";
import Section from "../../components/air-section/air-section";
import BoxList from "../../components/box-list/box-list";
import ScrollButton from "../../components/scroll-button/scroll-button";

const ProjektowanieGraficznePage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const experienceItems = [
    {
      name: "Spotkanie",
      to: "/",
    },
    {
      name: "Przygotowanie umowy",
      to: "/",
    },
    {
      name: "Pierwsze propozycje projektów",
      to: "/",
    },
    {
      name: "Dopracowanie wybranego projektu graficznego",
      to: "/",
    },
    {
      name: "Przekazanie gotowych plików do druku lub programowania",
      to: "/",
    },
  ];

  const data = useStaticQuery(
    graphql`
      {
        identyfikacja_wizualna: file(
          relativePath: {
            eq: "projektowanie-graficzne/identyfikacja_wizualna.png"
          }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        kreacja: file(
          relativePath: { eq: "projektowanie-graficzne/kreacja.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        opakowania: file(
          relativePath: { eq: "projektowanie-graficzne/opakowania.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        grafika_3d: file(
          relativePath: { eq: "projektowanie-graficzne/grafika_3d.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portfolio: allFile(
          filter: {
            sourceInstanceName: { eq: "portfolio" }
            extension: { eq: "mdx" }
          }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              sourceInstanceName
              relativeDirectory
              relativePath
              childMdx {
                frontmatter {
                  hero_color
                  color
                  section_bg
                  category
                  slug
                  title
                  language
                  description
                  short_description
                  created
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 900, maxHeight: 600) {
                        base64
                        src
                        aspectRatio
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                  gallery_alt
                  gallery {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        base64
                        src
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
                body
              }
            }
          }
        }
      }
    `
  );
  const itemSlugs = ["wallers", "yummypix", "zaklady-miesne-kozlowski"];
  const items = Portfolio.getNodesByLanguage("pl", data.portfolio.group)
    .filter((node: PortfolioNode) => {
      return itemSlugs.indexOf(node.childMdx.frontmatter.slug) >= 0;
    })
    .map((node: PortfolioNode) => {
      return node.childMdx.frontmatter;
    });

  return (
    <>
      <SEO
        title="Projektowanie graficzne"
        description="Studio graficzne agencji interaktywnej Aplinet to kreatywne projekty stron internetowych, logo, wizytówek oraz katalogów. Grafika 2D i&nbsp;3D ☎ +48 665 035 451"
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Projektowanie graficzne</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Wszystko zaczyna się od dobrego projektu. Tworzymy grafikę 2D, 3D,
              identyfikację wizualną na najwyższym poziomie
            </h2>
          </div>
          <h4>
            W&nbsp;naszych realizacjach, klienci docenili: kreatywność, ciekawe
            pomysły, oryginalne projekty, dobrze dobraną kolorystykę, jakość
            wykonania.
          </h4>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>
      <Section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Co zyskasz"
          headerText="Dlaczego warto przygotować grafikę w&nbsp;naszej agencji"
        />
        <p></p>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-c :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.identyfikacja_wizualna.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawiająca zaprojektowane logo i materiały firmowe firmy Yattai"
              title="Identyfikacja wizualna firmy Yattai"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="01 Identyfikacja wizualna"
              headerText="Skuteczna strategia budowy marki oraz koncepcji kumunikacji"
            />
            <p>
              Pomagamy klientom tworzyć lub rozwijać markę. Zdajemy sobie
              sprawę, że brak dobrego wizerunku negatywnie wpływa na prowadzony
              biznes. W&nbsp;naszych projektach staramy się wyróżnić na tle
              konkurencji, wzbudzić zaufanie oraz trafić do jak największej
              liczby odbiorców.
            </p>
            <p>
              Pomagamy doprecyzować strategię marki oraz komunikację
              z&nbsp;klientem. W&nbsp;tym celu możemy przygotować: logo,
              typografię, księgę znaku, materiały firmowe (teczki, kalendarze,
              notesy), materiały reklamowe.
            </p>
            <p>
              <strong>W każdej identyfikacji wizualnej staramy się:</strong>
            </p>
            <ul>
              <li>stworzyć wizerunek lub przeprowadzić rebranding,</li>
              <li>
                zadbać o&nbsp;oryginalność i&nbsp;niepowtarzalność projektu,
              </li>
              <li>
                wykonać grafikę która może zostać wydrukowana lub wykorzystana
                na stronie internetowej.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="02 Kreacja"
              headerText="Oferujemy kompleksowe usługi graficzne"
            />
            <p>
              We współczesnej komunikacji z&nbsp;klientem, obraz jest równie
              ważny jak słowo. Uwagę ludzi przyciąga grafika, zdjęcia, ciekawy{" "}
              <i>CLAIM</i>. Dlatego odpowiednie kreacje graficzne mają ogromny
              wpływ na skuteczność prowadzonych działań marketingowych.
            </p>
            <p>
              Dzięki profesjonalnym projektom graficznym, możesz zyskać większą
              rozpoznawalność i&nbsp;świadomość wśród swoich konsumentów. Warto
              tworzyć grafikę w&nbsp;agencji która może zapewnić realizację
              wszystkich Twoich koncepcji. Kreacja graficzna obejmuje m.in.:
              Grafikę www, materiały reklamowe wraz z&nbsp; identyfikacją
              wizualną, animację reklamową, key visual (element
              charakterystyczny marki), projekty POS (wzmacniające sprzedaż).
            </p>
            <p>
              <strong>Nasi graficy postarają się:</strong>
            </p>
            <ul>
              <li>
                przygotować kreatywne, skrojone na miarę kreacje graficzne,
              </li>
              <li>
                zaprojektować estetyczne banery reklamowe, infografiki,
                katalogi,
              </li>
              <li>stworzyć profesjonalne, wysokiej jakości grafiki.</li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.kreacja.childImageSharp.fluid}
              className="image-test"
              alt="Modelowanie 3D przedstawiające dwie bajkowe postacji"
              title="Kracja graficzna wykonana przez profesjonalne studio graficzne"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-c :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.opakowania.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawia całą linię opakowań przygotowanych w oryginalnej, ciekawej kolorystyce"
              title="Projekt opakowań na mleko skondensowane"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="03 Opakowania"
              headerText="Tworzymy niebanalne opakowania reklamowe i&nbsp;etykiety produktów"
            />
            <p>
              Dobre produkty zasługują na odpowiedną oprawę. Wyróżniające się
              opakowanie to częsty powód dla którego klient sięga właśnie po
              Twój produkt. Zła etykieta lub opakowanie może zniechęcić do
              zakupów!
            </p>
            <p>
              Projektujemy opakowania, które przyciągają wzrok konsumentów,
              przyciągają wzrok klientów. Jako profesjonalne studio graficzne,
              możemy zapewnić indywidualne podejście do projektu, stworzyć
              oryginalną linie plastyczną, dostosować kształt opakowania do
              produktu.
            </p>
            <p>
              <strong>Dzięki dobrze zaprojektowanemu opakowaniu:</strong>
            </p>
            <ul>
              <li>
                wyróżnisz i&nbsp;zaznaczysz obecność swojego produktu na pułkach
                sklepowych,
              </li>
              <li>nasze opakowania budzą pozytywne emocje wśród klientów.</li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="04 Grafika</br>2D i&nbsp;3D"
              headerText="Przygotujemy prawie każdy rodzaj grafiki 2D i&nbsp;3D"
            />
            <p>
              Tworzymy wizualizacje 3D, budynków, konstrukcji, maszyn oraz
              opakowań. Możemy przygotować zarówno modelowanie, teksturowanie,
              renderowanie oraz animację. W&nbsp;naszych pracach potrafimy
              przekazać zasadę działania produktu, lub przeprowadzić użytkownika
              przez prezentację funkcjonalności.
            </p>
            <p>
              Możemy również stworzyć każdy rodzaj grafiki 2D oraz przygotować
              pliki do druku lub na stronę internetową.
            </p>
            <p>
              <strong>Przekonaj się, że:</strong>
            </p>
            <ul>
              <li>
                nasze studio graficzne działa kompleksowo w&nbsp;projektach 2D
                oraz 3D,
              </li>
              <li>poradzimy sobie z&nbsp;ambitnymi pomysłami.</li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.grafika_3d.childImageSharp.fluid}
              className="image-test"
              alt="Przygotowane rendery 3D produktów AmeriGas"
              title="Modele 3D butli gazowych firmy AmeriGas"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.opakowania.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="05 Animacja"
              headerText="Możemy przygotować interaktywną grafikę lub animacje"
            />
            <p>
              Animacja doskonale sprawdzi się przy wizualizacji najnowszych
              technologii, produktów lub przygotowaniu filmów szkoleniowych.
            </p>
            <p>
              Tworzenie animacji daje bardzo duże pole do działania: zmiany
              rozmiaru, formy, kolorystyki i tekstury, położenia obiektów,
              manipulacji światłem, modelowania interakcji między obiektami.
            </p>
            <p>
              <strong>
                Dzięki interaktywnej grafice i&nbsp;animacjom możemy:
              </strong>
            </p>
            <ul>
              <li>
                wykonać reklamę, zaprezentować produkt, pokazać proces
                technologiczny,
              </li>
              <li>zajrzeć w miejsca niedostępnych dla kamery,</li>
              <li>ożywić wymodelowane postacie każdej produkcji.</li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="05 Projekty online"
              headerText="Przygotujemy projekt graficzny strony internetowej, aplikacji webowej lub aplikacji mobilnej"
            />
            <p>
              Posiadamy również doświadczenie w tworzeniu grafiki do
              wykorzystania online. Potrafimy zaplanować layout strony
              internetowej, aplikacji webowej oraz mobilnej zwracając uwagę na
              dobre praktyki UX oraz UI.
            </p>
            <p>
              W naszych realizacjach proponujemy klientom różny układ ustawienia
              treści oraz wariantów kolorystycznych. Przedstawione propozycje
              pokazujemy na kilku urządzeniach (telefon, tablet, monitor).
              Uzupełniamy projekt dostarczoną przez klienta treścią, aby jak
              najlepiej wyobrazić sobie końcowy efekt w postaci zaprogramowanej
              strony lub aplikacji.
            </p>
            <p>
              <strong>Nasze projekty stron i aplikacji:</strong>
            </p>
            <ul>
              <li>są nowoczesne i zgodne ze wszystkimi zasadami UI oraz UX,</li>
              <li>uwzględniają różne rozdzielczości ekranów,</li>
              <li>spełniają wymagania programistów.</li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.kreacja.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <div className="align-center">
          <Button color="primary" type="inversed" to="/kontakt" language="pl">
            Wyceń niepowtarzalny projekt graficzny
          </Button>
        </div>
      </Section>
      <Section className="container">
        <HeaderBig
          tag="h2"
          span="Projekty graficzne"
          headerText="Kilka naszych realizacji"
        />
        <PortfolioList items={items}></PortfolioList>
        <div className="align-center">
          <Button
            color="primary"
            type="inversed"
            to="/realizacje"
            language="pl"
          >
            Zobacz wszystkie realizacje
          </Button>
        </div>
      </Section>
      <Section backgroundColor="#000" className="container">
        <HeaderBig
          tag="h2"
          span="Profity kreacji</br>graficznej"
          spanColor="#484848"
          headerText="Po co tworzyć profesjonalną grafikę"
          color="#50817b"
        />
        <BoxList
          boxItems={[
            {
              title: `Budowa solidnej marki`,
              span: "01",
              description:
                "Przygotowanie identyfikacji wizualnej, brandu marki, logotypu, materiałów reklamowych pozwala zaistnieć w&nbsp;świadomości klientów. Spójny wizerunek to wyznacznik profesjonalizmu, dzięki temu firma zyskuje w&nbsp;oczach klientów.",
            },
            {
              title: `Promocja produktów`,
              span: "02",
              description:
                "Kolorowe, oryginalne opakowanie przyciąga klientów i&nbsp;realnie przekłada się na zwiększenie sprzedaży. Użycie pudełka do dialogu z&nbsp;konsumentem jest świetnym pomysłem i&nbsp;nie warto na nim oszczędzać.",
            },
            {
              title: `Materiały reklamowe`,
              span: "03",
              description:
                "Gadżety reklamowe to doskonały sposób na budowe wizerunku. Zadaniem upominków wręczanych klientom, jest dobre kojarzenie firmy w przyszłości. Warto również pamiętać, że obdarowana osoba staje sam staje się reklamą.",
            },
          ]}
        />
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <HeaderBig
              tag="h2"
              span="Przegieg współpracy"
              headerText="Etapy współpracy przy tworzeniu projektów graficznych"
            />
            <p>
              Przed złożeniem zapytania ofertowego, postaraj się odpowiedzieć na
              pytanie "po jaką usługę przychodzisz do agencji". Zastanów się,
              jakiego zakresu kompetencji potrzebujesz, w&nbsp;jakim obszarze
              mamy wspierać Twój pomysł? Podsumuj swoje oczekiwania
              i&nbsp;przygotuj pytania.
            </p>
            <p>
              Aby ułatwić to zadanie, przygotowaliśmy etapy prawidłowo
              zaplanowanej współpracy przy tworzeniu projektów graficznych.
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                type="default"
                to="/kontakt"
                language="pl"
              >
                Wyceń swój projekt
              </Button>
            </div>
          </div>
          <ul className="experience count container">
            {experienceItems.map((item, index) => {
              return (
                <InView key={index} rootMargin="-49% 0px -49% 0px">
                  {({ inView, ref, entry }) => (
                    <li ref={ref} className={inView ? "active" : ""}>
                      <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                    </li>
                  )}
                </InView>
              );
            })}
          </ul>
        </StickyContainer>
      </Section>

      {/* -----------------------------------------` */}
      <CallFooter />
    </>
  );
};

export default ProjektowanieGraficznePage;
